import { useI18n } from '../../hooks/use-i18n';
export const USE_REGISTERED_BOX_HEADER_TRANSLATIONS = {
    topText: {
        delivery: 'delivery',
    },
    middleText: {
        waiting_for_goods: 'waitingforGoods',
        return_created: 'box.returnCreated',
    },
    bottomText: {
        inform_about_eta_upon_arrival_at_distribution_terminal: 'informAboutETAUponArrivalAtDistributionTerminal',
        book_your_return: 'box.bookYourReturn',
        pin_code_change_locker_verification: 'pinCodeChangeLockerVerification',
    },
};
export function useRegisteredBoxHeader({ returnEnabled, needsVerification, canSwitchLocker, distributionCity, merchantName, }) {
    const { t } = useI18n();
    const value = {
        topText: t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.topText.delivery),
        middleText: t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.middleText.waiting_for_goods, {
            merchant: merchantName,
        }),
        bottomText: t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.bottomText
            .inform_about_eta_upon_arrival_at_distribution_terminal, {
            distributionCity,
        }),
    };
    if (returnEnabled) {
        value.topText = null;
        value.middleText = t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.middleText.return_created, {
            merchant: merchantName,
        });
        value.bottomText = t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.bottomText.book_your_return);
    }
    if (needsVerification && canSwitchLocker) {
        value.bottomText = t(USE_REGISTERED_BOX_HEADER_TRANSLATIONS.bottomText.pin_code_change_locker_verification);
    }
    return value;
}
