import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { lockers } from '@budbee/js/dist/tracking';
import { useI18n } from '../../hooks/use-i18n';
import { useBoxOrder, useIsBoxReturn } from '../../state/box-order/hooks';
import { useCountryCode } from '../../state/locale/hooks';
import { IconSize } from '../../styles';
import { getBrand } from '../../utils/brand/get-brand';
import imageList from '../../utils/image-list';
import { AnimatedView } from '../shared/animated-view';
import { Icon } from '../shared/icon';
import { View } from '../shared/view';
import { styles } from './style';
export const JointLockerBanner = () => {
    const { t } = useI18n();
    const boxOrder = useBoxOrder();
    const isReturn = useIsBoxReturn();
    const brand = getBrand();
    const countryCode = useCountryCode();
    const [isVisible, setIsVisible] = useState(() => {
        var _a;
        if (!(boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.lockerAttributes)) {
            return false;
        }
        return lockers.shouldRenderJointLockerBanner(countryCode, ((_a = boxOrder === null || boxOrder === void 0 ? void 0 : boxOrder.lockerAttributes.color) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || null, brand);
    });
    if (!isVisible) {
        return null;
    }
    return (_jsxs(AnimatedView, { animation: "slideInDown", style: styles.root, children: [_jsx(View, { style: styles.leftIcon, children: _jsx(Icon, { src: imageList.InstaboxIcon, size: IconSize['3xl'] }) }), _jsx(View, { style: styles.message, children: isReturn ? t('jointLocker.returnModalHeading') : t('jointLocker.deliveryModalHeading') }), _jsx(View, { style: styles.close, onClick: () => setIsVisible(false), children: _jsx(Icon, { src: imageList.Close, size: IconSize.xs }) })] }));
};
