import { jsx as _jsx } from "react/jsx-runtime";
import { OrderHero } from '../order-hero';
import { useEstimatedBoxHeaderHero } from './use-estimated-box-header-hero';
export function EstimatedBoxHeaderHero({ merchantName, etaInformation, canSwitchLocker, status, parcelsPickedUpFromMerchant, needsVerification, }) {
    const orderHeroProps = useEstimatedBoxHeaderHero({
        needsVerification,
        merchantName,
        etaInformation,
        canSwitchLocker,
        status,
        parcelsPickedUpFromMerchant,
    });
    return _jsx(OrderHero, { ...orderHeroProps });
}
