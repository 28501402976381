import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useI18n } from '../../hooks/use-i18n';
import imageList from '../../utils/image-list';
import { BaseButton } from '../base-button';
import { View } from '../shared/view';
import { SwitchDeliveryTypeButton } from '../switch-delivery-type-button';
import { styles } from '../box-header/style';
export function RegisteredBoxHeaderActions({ authenticated, needsVerification, canSwitchDeliveryType, returnEnabled, toggleIdentificationModal, toggleReturnsModal, toggleSwitchDeliveryTypeModal, }) {
    const { t } = useI18n();
    if (!authenticated)
        return null;
    return (_jsxs(View, { style: styles.actionsWrapper, children: [needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('identifyWithBankID'), iconSrc: imageList.bankId, onClick: toggleIdentificationModal, variant: "primary" }) })), canSwitchDeliveryType && !needsVerification && (_jsx(View, { style: styles.actionContainer, children: _jsx(SwitchDeliveryTypeButton, { toggleSwitchDeliveryTypeModal: toggleSwitchDeliveryTypeModal }) })), returnEnabled && (_jsx(View, { style: styles.actionContainer, children: _jsx(BaseButton, { label: t('bookReturnTitle'), iconSrc: imageList.Return, onClick: toggleReturnsModal, variant: "primary" }) }))] }));
}
