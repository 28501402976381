import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from '@css';
import { useIsOrderFailed } from '../../../hooks/use-is-order-failed';
import imageList from '../../../utils/image-list';
import { Icon } from '../../shared/icon';
import { View } from '../../shared/view';
import { styles } from './style';
export function BudbeeOrderHero({ topText, middleText, bottomText, badgeText }) {
    const isFailed = useIsOrderFailed();
    const icon = getIconSrc({ isFailed });
    return (_jsxs(View, { style: styles.root, children: [_jsxs(View, { style: styles.textContainer, children: [!!topText && _jsx("p", { className: css(styles.topText), children: topText }), !!middleText && _jsx("p", { className: css(styles.middleText), children: middleText }), !!badgeText && (_jsx(View, { style: styles.badge, children: _jsx("p", { className: css(styles.badgeText), children: badgeText }) })), !!bottomText && _jsx("p", { className: css(styles.bottomText), children: bottomText })] }), _jsx(View, { style: styles.icon, children: _jsx(Icon, { size: 160, src: icon }) })] }));
}
function getIconSrc({ isFailed }) {
    if (isFailed) {
        return imageList.ParallelHexagonsRed;
    }
    return imageList.ParallelHexagons;
}
