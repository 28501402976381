import { BoxParcelStatus, EtaDelayReason, EtaMissingReason } from '@budbee/js/dist/tracking';
import { useDateFormat } from '../../hooks/use-date-format';
import { useI18n } from '../../hooks/use-i18n';
export const ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS = {
    topText: {
        delivery: 'delivery',
        estimated_delivery_time: 'estimatedDeliveryTime',
    },
    middleText: {
        no_eta: {
            hidden_until_sorted: 'order.status.title.no_eta.hidden_until_sorted',
            not_yet_on_last_mile: 'order.status.title.no_eta.not_yet_on_last_mile',
        },
        merchant_delayed: 'order.status.title.merchant_delayed',
    },
    bottomText: {
        no_eta: {
            hidden_until_sorted: 'order.status.description.no_eta.hidden_until_sorted',
            not_yet_on_last_mile: 'order.status.description.no_eta.not_yet_on_last_mile',
        },
        merchant_delayed: 'order.status.description.merchant_delayed',
        box_delivery_information: 'boxDeliveryInformation',
        pin_code_change_locker_verification: 'pinCodeChangeLockerVerification',
        pin_code_instructions_verification: 'pinCodeInstructionsVerification',
    },
    badgeText: {
        parcel_not_yet_received: 'parcelNotYetReceived',
    },
};
export function useEstimatedBoxHeaderHero({ needsVerification, merchantName, etaInformation, canSwitchLocker, status, parcelsPickedUpFromMerchant, }) {
    const { t } = useI18n();
    const format = useDateFormat();
    const value = {
        topText: t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.topText.delivery),
    };
    if (etaInformation.missingReason === EtaMissingReason.ETA_HIDDEN_UNTIL_PARCEL_SORTED) {
        value.middleText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.middleText.no_eta.hidden_until_sorted, {
            merchant: merchantName,
        });
        value.bottomText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.no_eta.hidden_until_sorted);
    }
    else if (etaInformation.missingReason === EtaMissingReason.NOT_YET_ON_LAST_MILE) {
        value.middleText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.middleText.no_eta.not_yet_on_last_mile);
        value.bottomText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.no_eta.not_yet_on_last_mile);
    }
    else if (etaInformation.eta) {
        if (etaInformation.delayReason === EtaDelayReason.MERCHANT_DELAYED) {
            value.middleText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.middleText.merchant_delayed, {
                merchant: merchantName,
            });
            value.bottomText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.merchant_delayed, {
                eta: format.formatDateTimeWithTilde(etaInformation.eta, 'lowercase'),
            });
        }
        else {
            value.topText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.topText.estimated_delivery_time);
            value.middleText = format.formatDateTimeWithTilde(etaInformation.eta);
            value.bottomText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.box_delivery_information);
        }
    }
    if (needsVerification) {
        const verificationInstructions = canSwitchLocker
            ? ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.pin_code_change_locker_verification
            : ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.bottomText.pin_code_instructions_verification;
        value.bottomText = t(verificationInstructions);
    }
    if (status !== BoxParcelStatus.Collected && !parcelsPickedUpFromMerchant) {
        value.badgeText = t(ESTIMATED_BOX_HEADER_HERO_TRANSLATIONS.badgeText.parcel_not_yet_received);
    }
    return value;
}
