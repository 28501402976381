import { EtaMissingReason } from '@budbee/js/dist/tracking';
/**
 * Check if we can show the estimated box header view
 *
 * Must be updated when new conditions are added to the ETA information
 */
export function shouldShowEstimatedBoxHeader(etaInformation) {
    if (!etaInformation) {
        return false;
    }
    //* return false if all fields are falsy
    if (!Object.values(etaInformation).some(Boolean)) {
        return false;
    }
    if (!etaInformation.eta) {
        //* return false if no ETA is available and no missing reason
        if (etaInformation.missingReason == null) {
            return false;
        }
        //* return false if the missing reason isn't supported in the ui
        if (!SUPPORTED_MISSING_REASONS.includes(etaInformation.missingReason)) {
            return false;
        }
    }
    return true;
}
const SUPPORTED_MISSING_REASONS = [
    EtaMissingReason.ETA_HIDDEN_UNTIL_PARCEL_SORTED,
    EtaMissingReason.NOT_YET_ON_LAST_MILE,
];
