export function getItem(key) {
    const val = localStorage.getItem(key);
    if (val === undefined) {
        return null;
    }
    return val;
}
export function setItem(key, value) {
    localStorage.setItem(key, value);
}
export function removeItem(key) {
    localStorage.removeItem(key);
}
