import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement, useEffect } from 'react';
import { css } from '@css';
import { useDispatch } from 'react-redux';
import { DeliveryPreferencesSummary } from '../../../components/delivery-preferences-summary';
import { useI18n } from '../../../hooks/use-i18n';
import { useTrackingApi } from '../../../hooks/use-tracking-api';
import { showStep as editSettingAction } from '../../../state/preface/actions';
import { getBrand } from '../../../utils/brand/get-brand';
import { TIMETABLE } from '../../../utils/steps';
import { ActionListItem } from '../../action-list-item';
import { DeliveryWindowSummaryItem } from '../../delivery-window-summary-item';
import { View } from '../../shared/view';
import { styles } from './style';
export const Summary = ({ estateType, accessMode, deliveryMethod, deliveryNotification, alternativeLeaveWithNeighbour, floor, countryCode, consignment, pendingTimeWindow, onContinue, canContinueChanged, token, authToken, routedEta, orderStatus, isReturn, allowedToBookDelivery, careOf, specialInstructions, showDeliveryTime = true, }) => {
    const { t, language } = useI18n();
    const trackingApi = useTrackingApi();
    const dispatch = useDispatch();
    const brand = getBrand();
    useEffect(() => {
        if (!token || !authToken) {
            return;
        }
        canContinueChanged(true);
        onContinue((next, onError) => {
            trackingApi.confirmSummary(authToken, token).call().then(next).catch(onError);
        });
    }, []);
    return (_jsxs(View, { className: css(styles.root), children: [brand !== 'porterbuddy' && (_jsx("span", { className: css(styles.title), children: t('summary.isThisInformationCorrect') })), showDeliveryTime && (_jsx(ActionListItem, { variant: "edit", contentContainerStyle: styles.actionListItem, leftTitle: t('summary.deliveryTime'), rightTitle: createElement(DeliveryWindowSummaryItem, {
                    routedEta,
                    orderStatus,
                    isReturn,
                    consignment: consignment && pendingTimeWindow
                        ? {
                            ...consignment,
                            start: pendingTimeWindow.timeWindow.start,
                            stop: pendingTimeWindow.timeWindow.stop,
                        }
                        : consignment,
                    countryCode,
                    timeWindow: pendingTimeWindow,
                    language,
                }), disabled: !allowedToBookDelivery, onPress: () => dispatch(editSettingAction(TIMETABLE)) })), _jsx(DeliveryPreferencesSummary, { estateType: estateType, accessMode: accessMode, deliveryMethod: deliveryMethod, deliveryNotification: deliveryNotification, alternativeLeaveWithNeighbour: alternativeLeaveWithNeighbour, floor: floor, careOf: careOf || '', instructions: specialInstructions, showPreface: (preface) => dispatch(editSettingAction(preface)) })] }));
};
