import { CancellationAlertLevel, ConsignmentType, locale, MissCategory, orderSettings, ParcelStatus, } from '@budbee/js/tracking';
import { useEta } from '../../../state/eta/hooks';
import { useLanguage, useTimeZone } from '../../../state/locale/hooks';
import { useConspectus, useRoutedEta } from '../../../state/order/hooks';
import { useLatestPosition } from '../../../state/positions/hooks';
import { getBrand } from '../../../utils/brand/get-brand';
import { getCancelledTexts, isConsignmentCancelled } from '../../../utils/cancellation';
import { localizeMerchantName } from '../../../utils/locale';
import { useI18n } from '../../../hooks/use-i18n';
import { useIsDelayed } from './useEtaHeader';
import { capitalize } from '../../../utils/strings';
function getReturnHeaderText(status) {
    switch (status) {
        case ParcelStatus.Collected:
            return 'order.pickedUpSuccess';
        case ParcelStatus.ReturnedToTerminal:
            return 'order.onWayToMerchant';
        case ParcelStatus.ReturnedToMerchant:
            return 'order.returnedToMerchant';
        default:
            return '';
    }
}
export function useTrackingHeaderText({ showDeliveryConfirmed, }) {
    var _a, _b;
    const routedEta = useRoutedEta();
    const conspectus = useConspectus();
    const timeZone = useTimeZone();
    const language = useLanguage();
    const position = useLatestPosition();
    const eta = useEta();
    const { t, i18n } = useI18n();
    const brand = getBrand();
    const isDelayed = useIsDelayed();
    // identification is a part of conspectus,
    // make sure it's loaded before continuing
    // to show the order
    if (!conspectus || !conspectus.identification) {
        return {
            topText: '',
            middleText: '',
            bottomText: '',
            badgeText: '',
        };
    }
    const { consignment, orderLocation, driver, consumer } = conspectus;
    const isReturn = Boolean(consignment && consignment.type === ConsignmentType.RETURN);
    const currentStatus = conspectus.status.state;
    const { countryCode } = conspectus.address;
    const [firstName] = (_a = consumer === null || consumer === void 0 ? void 0 : consumer.name.split(' ')) !== null && _a !== void 0 ? _a : [];
    const headerTexts = {
        topText: '',
        middleText: t('waitingforGoods', {
            merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
        }),
        bottomText: t('informDeliveryTime'),
        badgeText: '',
    };
    if (consignment) {
        headerTexts.topText = isReturn ? t('plannedReturnTime') : t('plannedDeliveryTime');
        headerTexts.bottomText =
            orderSettings.orderRequirements(t, conspectus, conspectus.identification, conspectus.consumer.name, isReturn) || '';
        headerTexts.middleText = timeZone
            ? i18n.etaText(routedEta
                ? {
                    date: new Date(routedEta),
                    remainingStopCount: 0,
                }
                : null, currentStatus, isReturn, consignment, countryCode, language, timeZone)
            : null;
    }
    else if (currentStatus === ParcelStatus.NotStarted &&
        orderLocation &&
        orderLocation.parcelsPickedUpFromMerchant) {
        headerTexts.middleText = t('goodsInTransit', {
            merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
        });
        headerTexts.bottomText = t('goodsInTransitToTerminal', {
            distributionCity: locale.localizeCityName(orderLocation.distributionCity, language),
        });
    }
    if ([
        ParcelStatus.Collected,
        ParcelStatus.OnRouteDelivery,
        ParcelStatus.CollectedShippingLabel,
        ParcelStatus.CrossDocked,
    ].includes(currentStatus)) {
        headerTexts.topText = conspectus.driver
            ? t('driversEta', {
                driver: orderSettings.formatDriverName(conspectus.driver.name || ''),
            })
            : t('noEta');
        if (isDelayed) {
            headerTexts.bottomText = t('homeOrder.noDeliveryTimeEstimate');
        }
    }
    if (isReturn &&
        [
            ParcelStatus.Collected,
            ParcelStatus.ReturnedToTerminal,
            ParcelStatus.ReturnedToMerchant,
        ].includes(currentStatus)) {
        headerTexts.middleText = t(getReturnHeaderText(currentStatus), {
            merchant: localizeMerchantName(conspectus.merchant.name, countryCode),
        });
        headerTexts.topText = '';
        headerTexts.bottomText = t('reviewModal.addReviewOfservice', { brand: capitalize(brand) });
    }
    if (conspectus.identification &&
        !isConsignmentCancelled(consignment) &&
        currentStatus !== ParcelStatus.Delivered &&
        currentStatus !== ParcelStatus.Miss) {
        if (conspectus.identification.needsVerification) {
            const merchant = localizeMerchantName(conspectus.merchant.name, countryCode);
            headerTexts.bottomText = '';
            headerTexts.topText = '';
            headerTexts.middleText = t('order.identificationRequiredBankId', {
                merchant,
            });
        }
    }
    if (currentStatus === ParcelStatus.Delivered) {
        const merchant = localizeMerchantName(conspectus.merchant.name, countryCode);
        if (conspectus.status.outsideDoor) {
            headerTexts.middleText = t('order.deliveredOutsideDoorSuccess', {
                merchant,
            });
        }
        else if (conspectus.status.withNeighbour) {
            headerTexts.middleText = t('order.deliveredToNeighbourSuccess', {
                merchant,
            });
        }
        else {
            headerTexts.middleText = t('order.deliveredSuccess', {
                merchant,
            });
        }
        headerTexts.topText = '';
        headerTexts.bottomText = t('reviewModal.addReviewOfservice', { brand: capitalize(brand) });
    }
    else if (currentStatus === ParcelStatus.Miss) {
        headerTexts.middleText = isReturn ? t('order.pickedUp.failed') : t('order.delivered.failed');
        headerTexts.topText = '';
        headerTexts.bottomText = getMissReasonText({
            t,
            conspectus,
        });
    }
    if (isConsignmentCancelled(consignment) || conspectus.cancellation) {
        const [middleText, bottomText] = getCancelledTexts(conspectus, isReturn, t);
        headerTexts.topText = '';
        headerTexts.middleText = middleText;
        headerTexts.bottomText = bottomText;
        if (((_b = consignment === null || consignment === void 0 ? void 0 : consignment.cancellation) === null || _b === void 0 ? void 0 : _b.level) === CancellationAlertLevel.NORMAL) {
            headerTexts.topText = t('homeDeliveryStatusTitle');
        }
    }
    if (brand === 'porterbuddy') {
        headerTexts.topText = t('delivery');
        if (isReturn) {
            headerTexts.topText = t('return');
        }
        if (driver && position && eta && !isConsignmentCancelled(consignment)) {
            const remainingStops = eta.remainingStopCount <= 10 ? String(eta.remainingStopCount) : '10+';
            headerTexts.badgeText =
                eta.remainingStopCount > 1
                    ? t('mapTextRemainingStops', { remainingStops })
                    : t('mapTextRemainingStopSingular');
        }
        if (currentStatus === ParcelStatus.Delivered || currentStatus === ParcelStatus.Miss) {
            headerTexts.badgeText = '';
        }
        if (currentStatus === ParcelStatus.Delivered &&
            conspectus.settings.temperatureConfirmationRequired) {
            headerTexts.middleText = showDeliveryConfirmed
                ? t('trackingHeader.temperatureDeliveryConfirmed.middleText')
                : t('trackingHeader.temperatureConfirmationRequired.middleText', { userName: firstName });
            headerTexts.bottomText = showDeliveryConfirmed
                ? t('trackingHeader.temperatureDeliveryConfirmed.bottomText')
                : t('trackingHeader.temperatureConfirmationRequired.bottomText');
        }
    }
    return headerTexts;
}
function getMissReasonText({ t, conspectus, }) {
    const { orderMiss, consumer, settings } = conspectus;
    const { missCategory } = orderMiss !== null && orderMiss !== void 0 ? orderMiss : {};
    const { name: recipientName } = consumer !== null && consumer !== void 0 ? consumer : {};
    const { recipientMinimumAge } = settings !== null && settings !== void 0 ? settings : {};
    if (missCategory === MissCategory.WRONG_RECIPIENT) {
        if (recipientMinimumAge > 0) {
            return t('order.missed.reason.incorrectAge', {
                age: recipientMinimumAge,
            });
        }
        return t('order.missed.reason.incorrectReceiver', {
            recipient_name: recipientName,
        });
    }
    return '';
}
