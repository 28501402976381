import { lockers as lockerUtils } from '@budbee/js/tracking';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';
import * as momentAll from 'moment-timezone';
momentDurationFormatSetup(momentAll);
export function formatLockerOpenHours({ openHours, date = new Date(), }) {
    var _a;
    if (!openHours) {
        return null;
    }
    const parseFormat = 'HH:mm:ss';
    const timeFormat = 'HH:mm';
    const dayOfWeekISO = new Date(date).toLocaleString('en', { weekday: 'long' }).toLowerCase();
    const hours = (_a = Object.entries(openHours).find(([day]) => day === dayOfWeekISO)) === null || _a === void 0 ? void 0 : _a[1];
    if (!hours) {
        return null;
    }
    const { open, close } = hours;
    if (!open || !close) {
        return null;
    }
    const validDates = moment(open, parseFormat).isValid() && moment(close, parseFormat).isValid();
    if (!validDates) {
        return null;
    }
    return [open, close].map((value) => moment(value, parseFormat).format(timeFormat)).join(' - ');
}
/**
 * Formats locker hours relative to current date. This is used when looking
 * at a locker in lists, callouts, live tracking etc.
 * If the locker is open -> "Open today until 19:00"
 * If the locker has not yet opened today -> "Opens today, 08:00"
 * If the locker opens tomrrow -> "Opens tomorrow, 15:00"
 * If the locker is not open -> "Opens Tuesday, 15:00"
 * If the locker never closes -> "Open 24/7"
 * If the locker never opens -> "Closed"
 */
export const getRelativeLockerOpeningHours = (openHours, date, countryCode, t) => {
    if (!openHours) {
        return [];
    }
    const translations = {
        open: t('itsOpen'),
        openUntil: (date) => t('openUntil', { date }),
        opens: t('opens'),
        closed: t('closed'),
        allDay: t('allDay'),
        everyDay: t('everyDay'),
        today: t('today'),
        tomorrow: t('tomorrow'),
    };
    return lockerUtils.formatLockerOpeningHoursRelative(openHours, date, countryCode, translations);
};
