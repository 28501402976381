/* eslint-disable */
export default {
    inputImage: require('./../img/icon/input-icon.svg'),
    logoGreen: require('./../img/logos/budbee_green.svg'),
    knockOnDoor: require('./../img/icon/knock-on-door.svg'),
    knockOnDoorInstabox: require('./../img/icon/knock-on-door-instabox.svg'),
    doorBell: require('./../img/icon/door-bell.svg'),
    doorBellInstabox: require('./../img/icon/door-bell-instabox.svg'),
    markerDeliveryAddress: require('./../img/tracking/delivery-address.svg'),
    markerDeliveryAddressRed: require('./../img/tracking/delivery-address-red.svg'),
    markerDeliveryPorterbuddy: require('./../img/tracking/marker-delivery-porterbuddy.svg'),
    markerDeliveryPorterbuddyWhite: require('./../img/tracking/marker-delivery-porterbuddy-white.svg'),
    climate: require('./../img/icon/climate.svg'),
    House: require('./../img/icon/house.svg'),
    edit: require('./../img/icon/edit.svg'),
    angleRightGreen: require('./../img/icon/angle-right-green.svg'),
    angleLeftGreen: require('./../img/icon/angle-left-green.svg'),
    Box: require('./../img/icon/box.svg'),
    pin: require('./../img/icon/pin.svg'),
    chevronRight: require('./../img/icon/chevron-right.svg'),
    chevronLeft: require('./../img/icon/chevron-left.svg'),
    bankId: require('./../img/icon/bank-id.svg'),
    bankIdInverted: require('./../img/logos/bankid_white.svg'),
    Geotag: require('./../img/icon/geotag.svg'),
    VehicleIconVan: require('./../img/tracking/van.svg'),
    VehicleIconElectricVan: require('./../img/tracking/electric_van.svg'),
    VehicleIconEnvironmentalVan: require('./../img/tracking/environmental_van.svg'),
    VehicleIconBicycle: require('./../img/tracking/bicycle.svg'),
    VehicleIconSantasSleigh: require('./../img/tracking/santa.svg'),
    VehicleInstabox: require('./../img/tracking/van_instabox.svg'),
    VehiclePorterbuddy: require('./../img/tracking/vehicle-porterbuddy.svg'),
    LockerPin: require('./../img/icon/locker-pin.png'),
    LockerPinDisabled: require('./../img/icon/locker-pin-disabled.png'),
    LockerPinCurrent: require('./../img/icon/locker-pin-current.png'),
    LockersGroupedPin: require('./../img/icon/lockers-grouped-pin.png'),
    Locker: require('./../img/icon/locker.svg'),
    ArrowLeft: require('./../img/icon/arrow-left.svg'),
    ArrowRight: require('./../img/icon/arrow-right.svg'),
    Close: require('../img/icon/close.svg'),
    Search: require('./../img/icon/search.svg'),
    NavigationSecondaryGreen: require('./../img/icon/navigation-secondary-green.svg'),
    ConsumerAddress: require('./../img/icon/consumer-address.png'),
    Place: require('./../img/icon/place.png'),
    Checkmark: require('./../img/icon/checkmark.svg'),
    InfoFilledGreen: require('./../img/icon/info-filled-green.svg'),
    InfoFilledRed: require('./../img/icon/info-filled-red.svg'),
    InfoFilledPurple: require('./../img/icon/info-filled-purple.svg'),
    Star: require('./../img/icon/star.svg'),
    StarFilled: require('./../img/icon/star-filled.svg'),
    AlertWhite: require('./../img/icon/alert-white.svg'),
    InfoGreen: require('./../img/icon/info-green.svg'),
    Profile: require('./../img/icon/profile.svg'),
    Truck: require('./../img/icon/truck.svg'),
    Clock: require('./../img/icon/clock.svg'),
    Camera: require('./../img/icon/camera.svg'),
    LeaveOutsideDoor: require('./../img/icon/leave-outside-door.svg'),
    LeaveOutsideDoorInstabox: require('./../img/icon/leave-outside-door-instabox.svg'),
    LeaveWithNeighbour: require('./../img/icon/leave-with-neighbour-new.svg'),
    DoorCode: require('./../img/icon/door-code.svg'),
    DoorCodeInstabox: require('./../img/icon/door-code-instabox.svg'),
    Apartment: require('./../img/icon/apartment.svg'),
    ApartmentInstabox: require('./../img/icon/apartment-instabox.svg'),
    Intercom: require('./../img/icon/intercom.svg'),
    IntercomInstabox: require('./../img/icon/intercom-instabox.svg'),
    OpenDoor: require('./../img/icon/open-door.svg'),
    OpenDoorInstabox: require('./../img/icon/open-door-instabox.svg'),
    Edit: require('../img/icon/edit-rounded.svg'),
    Reverse: require('./../img/icon/reverse.svg'),
    Settings: require('./../img/icon/settings-new.svg'),
    Copy: require('./../img/icon/copy-text.svg'),
    Check: require('./../img/icon/check.svg'),
    Calendar: require('./../img/icon/calendar.svg'),
    Globe: require('./../img/icon/globe.svg'),
    GlobePorterbuddy: require('./../img/icon/globe-porterbuddy.svg'),
    InstaboxLogo: require('./../img/logos/instabox-logo.png'),
    ExternalLink: require('./../img/icon/external-link.svg'),
    ExternalLinkPorterbuddy: require('./../img/icon/external-link-porterbuddy.svg'),
    ExternalLinkInstabox: require('./../img/icon/external-link-instabox.svg'),
    budbeeFavicon: require('./../img/favicon/budbee_favicon.ico'),
    instaboxFavicon: require('./../img/favicon/instabox_favicon.ico'),
    porterbuddyFavicon: require('./../img/favicon/porterbuddy_favicon.ico'),
    CancelReturn: require('./../img/icon/cancel-return.svg'),
    AddNeighbour: require('./../img/icon/add-neighbour.svg'),
    TrashCan: require('./../img/icon/trash-can.svg'),
    CoDeliveryParcels: require('./../img/icon/house-and-parcels.svg'),
    Plus: require('./../img/icon/plus.svg'),
    Minus: require('./../img/icon/minus.svg'),
    Fingerprint: require('./../img/icon/fingerprint.svg'),
    FingerprintInstabox: require('./../img/icon/fingerprint-instabox.svg'),
    InfoCircle: require('./../img/icon/info-circle.svg'),
    InstabeeLockers: require('./../img/icon/instabee-lockers.png'),
    HexagonCheckmark: require('./../img/icon/hexagon-checkmark.svg'),
    HexagonCheckmarkAlt: require('./../img/icon/hexagon-checkmark-alt.svg'),
    CheckmarkPurple: require('./../img/icon/checkmark-purple.svg'),
    HexagonAlert: require('./../img/icon/hexagon-alert.svg'),
    Hexagon: require('./../img/icon/hexagon.svg'),
    Chat: require('./../img/icon/chat.svg'),
    ChatInstabox: require('./../img/icon/chat-instabox.svg'),
    ParallelHexagons: require('./../img/icon/parallel-hexagons.svg'),
    ParallelHexagonsRed: require('./../img/icon/parallel-hexagons-red.svg'),
    ParallelHexaconsYellow: require('./../img/icon/parallel-hexagons-yellow.svg'),
    Return: require('./../img/icon/return.svg'),
    SadFace: require('./../img/icon/sad-face.svg'),
    SpeedMeter: require('./../img/icon/speed-meter.svg'),
    Phone: require('./../img/icon/phone.svg'),
    CheckboxSelected: require('./../img/icon/checkbox-selected.svg'),
    CheckboxSelectedRed: require('./../img/icon/checkbox-selected-red.svg'),
    CheckboxPlus: require('./../img/icon/checkbox-plus.svg'),
    CheckboxUnselected: require('./../img/icon/checkbox-unselected.svg'),
    DoorOpen: require('./../img/icon/door-open.svg'),
    DoorOpenInstabox: require('./../img/icon/door-open-instabox.svg'),
    NoNeighbour: require('./../img/icon/no-neighbour.svg'),
    Refresh: require('./../img/icon/refresh.svg'),
    Floor: require('./../img/icon/floor.svg'),
    FloorInstabox: require('./../img/icon/floor-instabox.svg'),
    Instructions: require('./../img/icon/instructions.svg'),
    InstructionsInstabox: require('./../img/icon/special-instructions-instabox.svg'),
    NameOnDoor: require('./../img/icon/name-on-door.svg'),
    NameOnDoorInstabox: require('./../img/icon/name-on-door-instabox.svg'),
    Map: require('./../img/icon/map.svg'),
    PorterbuddyLogo: require('./../img/logos/porterbuddy_logo.png'),
    ChatBox: require('./../img/icon/chat-box.svg'),
    Cookies: require('./../img/icon/cookies.svg'),
    FaceSad: require('./../img/icon/face-sad.svg'),
    FaceOK: require('./../img/icon/face-ok.svg'),
    FaceHappy: require('./../img/icon/face-happy.svg'),
    FaceSadOutlined: require('./../img/icon/face-sad-outlined.svg'),
    FaceOKOutlined: require('./../img/icon/face-ok-outlined.svg'),
    FaceHappyOutlined: require('./../img/icon/face-happy-outlined.svg'),
    ThumbsUp: require('./../img/icon/thumbs-up.svg'),
    PhonePorterbuddy: require('./../img/icon/phone-porterbuddy.svg'),
    ChatPorterbuddy: require('./../img/icon/chat-porterbuddy.svg'),
    PorterbuddyHero: require('./../img/icon/porterbuddy-hero.png'),
    PorterbuddyClose: require('./../img/icon/porterbuddy-close.svg'),
    HeartsInCircle: require('./../img/icon/hearts-in-circle.svg'),
    InstaboxIcon: require('./../img/icon/instabox.svg'),
    InstaboxLocker: require('./../img/icon/instabox-locker.webp'),
    RedLocker: require('./../img/icon/dk-red-locker.png'),
    GreenLocker: require('./../img/icon/dk-green-locker.png'),
    InstaboxMascot: require('./../img/icon/instabox-mascot.svg'),
    PorterbuddyClock: require('./../img/icon/porterbuddy-clock.svg'),
    PorterbuddyUnselected: require('./../img/icon/checkbox-unselected-pb.svg'),
    PorterbuddySelected: require('./../img/icon/checkbox-selected-pb.svg'),
    PorterbuddyCheckmark: require('./../img/icon/checkmark-pb.svg'),
    PorterbuddyCheckmarUnselected: require('./../img/icon/porterbuddy-checkmark-unselected.svg'),
    PorterbuddyEmail: require('./../img/icon/porterbuddy-email.svg'),
    Email: require('./../img/icon/email.svg'),
    InstaboxEmail: require('./../img/icon/instabox-email.svg'),
    PorterbuddyPurplePlus: require('./../img/icon/porterbuddy-purple-plus.svg'),
    PorterbuddyRedMinus: require('./../img/icon/porterbuddy-red-minus.svg'),
    RedMinus: require('./../img/icon/red-minus.svg'),
    GreenPlus: require('./../img/icon/green-plus.svg'),
    NoNotification: require('./../img/icon/no-notification.svg'),
    CallMe: require('./../img/icon/call-me.svg'),
    InstaboxCheckmark: require('./../img/icon/instabox-checkmark.svg'),
    SquigglyLine: require('./../img/icon/squiggly-line.svg'),
    HouseInstabox: require('./../img/icon/house-instabox.svg'),
    MerchantInstabox: require('./../img/icon/merchant-instabox.svg'),
    ParcyFailed: require('./../img/icon/parcy-failed.svg'),
    ParcyNotReceived: require('./../img/icon/parcy-not-received.svg'),
    ParcyReceived: require('./../img/icon/parcy-received.svg'),
    ParcyOnRoute: require('./../img/icon/parcy-on-route.svg'),
    ParcyDelivered: require('./../img/icon/parcy-delivered.svg'),
    DoorClosed: require('./../img/icon/door-closed.svg'),
    Error: require('./../img/tracking/error.svg'),
    CheckmarkGreen: require('./../img/icon/checkmark-green.svg'),
    BudbeeThumbsUp: require('./../img/icon/budbee-thumbs-up.svg'),
    BudbeeThumbsDown: require('./../img/icon/budbee-thumbs-down.svg'),
};
